import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'username', label: 'User Name', sortable: true },
    { key: 'mail', label: 'Account', sortable: true },
    { key: 'coursename', label: 'Course Name', sortable: false },
    { key: 'coursestatus', label: 'Course status', sortable: false },
    { key: 'examname', label: 'Exam Name', sortable: true },
    { key: 'examstatus', label: 'Exam Status', sortable: true },
    { key: 'userexamid', label: 'Detail' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const items = ref()

  const dataMeta = computed(() => {
    const localItemsCount = items.value ? items.value.length : 0
    const from = perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0)
    return {
      from: from,
      to: (from + perPage.value) > totalUsers.value ? totalUsers.value + 1 : from + perPage.value,
      of: totalUsers.value,
    }
  })


  const fetchCourses = () => {
    store
      .dispatch('app-members-learning/fetchMemberRecords', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data } = response.data
        //console.log("resolved Data");
        items.value = data;
        totalUsers.value = data.length;
        // callback(data)
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const refetchData = () => {
    fetchCourses()
  }


  fetchCourses()



  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveCourseStatusVariant = status => {

    if (status) return 'success'
    return 'secondary'
  }
  const resolveCourseStatusText = status => {
    if (status) return 'completed'
    return 'unfinished'
  }
  const resolveExamStatusVariant = status => {

    if (status === 0 || status === 1 || status === 10) return 'secondary'
    if (status === 50) return 'warning'
    if (status === 100) return 'success'
    if (status === 200) return 'danger'
    return 'dark'
  }
  const resolveExamStatusText = status => {
    if (status === 0 || status === 1 || status === 10) return 'Not started'
    if (status === 50) return 'Pending for examer check'
    if (status === 100) return 'Success'
    if (status === 200) return 'Failed'
    return 'No exam defined'
  }


  const resolvePageroute = coursemode => {
    if (coursemode === 'IN-PERSON') return 'course-sessions'
    if (coursemode === 'ONLINE') return 'course-lessons'
    if (coursemode === 'HYBRID') return 'course-lessons'

    return 'course-lessons'
  }
  const resolveCourseContent = coursemode => {
    if (coursemode === 'IN-PERSON') return 'Sessions'
    if (coursemode === 'ONLINE') return 'Lectures'
    if (coursemode === 'HYBRID') return 'Lectures'

    return 'Lectures'
  }


  return {
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    items,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveCourseStatusVariant,
    resolveCourseStatusText,
    resolveExamStatusVariant,
    resolveExamStatusText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    resolvePageroute,
    resolveCourseContent,
  }
}
